@use "sass:meta";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$md-fimmprimary: (
    50 : #e0ebe4,
    100 : #b3cebc,#005a20
    200 : #80ad90,
    300 : #4d8c63,
    400 : #267341,
    500 : #005a20,
    600 : #00521c,
    700 : #004818,
    800 : #003f13,
    900 : #002e0b,
    A100 : #67ff7a,
    A200 : #34ff4e,
    A400 : #01ff22,
    A700 : #00e61e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-fimmaccent: (
  50 : #e0eae6,
    100 : #b3cbc1,
    200 : #80a898,
    300 : #4d856f,
    400 : #266a50,
    500 : #005031,
    600 : #00492c,
    700 : #004025,
    800 : #00371f,
    900 : #002713,
    A100 : #62ff9a,
    A200 : #2fff7a,
    A400 : #00fb5a,
    A700 : #00e151,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette($md-fimmprimary);


$app-accent: mat.m2-define-palette($md-fimmaccent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$app-typography: mat.m2-define-typography-config(
  $font-family: Inter
);

$fimmapp-theme: mat.m2-define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: $app-typography,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fimmapp-theme);


@import './styles/variables.scss';
@import 'styles/scss/abstracts/variables.scss';
@import 'styles/scss/style.scss';


@layer tw-base, bootstrap,primeng, tw-components, tw-utilities ;

@layer bootstrap {
  @include meta.load-css("bootstrap/dist/css/bootstrap.css");
}
@layer tw-base {
  @tailwind base;
}

@layer primeng {
  @include meta.load-css("./styles/theme.css");
  @include meta.load-css("primeng/resources/primeng.css");
}


@layer tw-components {
  @tailwind components;
}
@layer tw-utilities {
  @tailwind utilities;
}

:root {
  $primary: #010703;
}

* { // override bootstrap colors
  --bs-primary: #005a20;
  --bs-primary-rgb: 0, 90, 32;
  --bs-link-color-rgb: 0, 0, 0;
  $primary: #005a20;
  $theme-colors: (
    "primary": $primary
  );
}

:root{
  --primary-500:red;
}



html, body { height: 100%; }
body,
body .p-component { margin: 0; font-family: Inter, "Helvetica Neue", sans-serif; }

.p-datatable .p-datatable-thead > tr > th {
  font-weight: 500 !important;
  border: none;
}
button.p-highlight {
  background-color: #005a20aa;
  color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6fff9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #005a2089;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #005a20;
}
