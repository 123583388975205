/* Helpers
   ========================================================================== */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }

    100% {
        -webkit-transform: rotate(360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }

    100% {
        -webkit-transform: rotate(360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

// Min width - Max width

.mh-auto {
    min-height: initial!important;
    max-height: initial!important;
}
.mw-auto {
    min-width: initial!important;
    max-width: initial!important;
}

// Min width

.mw-250 {
    min-width: rem(250);
}

.mw-350 {
    min-width: rem(350);
}

.w-250 {
    width: rem(250);
}

.w-350 {
    width: rem(350);
}


// Overflow scroll
.scroll-xs {
    min-height: rem(50);
    max-height: rem(75);
    overflow-y: auto;
}

.scroll-md {
    min-height: rem(75);
    max-height: rem(100);
    overflow-y: auto;
}

.scroll-lg {
    min-height: rem(100);
    max-height: rem(125);
    overflow-y: auto;
}

.scroll-xl {
    min-height: rem(125);
    max-height: rem(150);
    overflow-y: auto;
}
// No hover

.no-hover{
    pointer-events: none;
}

// Cursor
.cursor-alias {
    cursor: alias;
}

.cursor-all-scroll {
    cursor: all-scroll;
}

.cursor-auto {
    cursor: auto;
}

.cursor-cell {
    cursor: cell;
}

.cursor-context-menu {
    cursor: context-menu;
}

.cursor-col-resize {
    cursor: col-resize;
}

.cursor-copy {
    cursor: copy;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-default {
    cursor: default;
}

.cursor-e-resize {
    cursor: e-resize;
}

.cursor-ew-resize {
    cursor: ew-resize;
}

.cursor-grab {
    cursor: -webkit-grab;
    cursor: grab;
}

.cursor-grabbing {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.cursor-help {
    cursor: help;
}

.cursor-move {
    cursor: move;
}

.cursor-n-resize {
    cursor: n-resize;
}

.cursor-ne-resize {
    cursor: ne-resize;
}

.cursor-nesw-resize {
    cursor: nesw-resize;
}

.cursor-ns-resize {
    cursor: ns-resize;
}

.cursor-nw-resize {
    cursor: nw-resize;
}

.cursor-nwse-resize {
    cursor: nwse-resize;
}

.cursor-no-drop {
    cursor: no-drop;
}

.cursor-none {
    cursor: none;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-progress {
    cursor: progress;
}

.cursor-row-resize {
    cursor: row-resize;
}

.cursor-s-resize {
    cursor: s-resize;
}

.cursor-se-resize {
    cursor: se-resize;
}

.cursor-sw-resize {
    cursor: sw-resize;
}

.cursor-text {
    cursor: text;
}

.cursor-w-resize {
    cursor: w-resize;
}

.cursor-wait {
    cursor: wait;
}

.cursor-zoom-in {
    cursor: zoom-in;
}

.cursor-zoom-out {
    cursor: zoom-out;
}

// Color
.cl-primary {
    color: $p-color !important;
}

.cl-secondary {
    color: $s-color !important;
}

.cl-third {
    color: $t-color !important;
}

.cl-fourth {
    color: $f-color !important;
}

.cl-medium {
    color: $m-color !important;
}

.cl-dark {
    color: $k-color !important;
}

.cl-light {
    color: $b-color !important;
}

.cl-danger {
    color: $danger-color !important;
}

.cl-success {
    color: $success-color !important;
}

.cl-warning {
    color: $warning-color !important;
}

.cl-info {
    color: $info-color !important;
}

.cl-link {
    color: $link-color !important;
}

.cl-grey-a {
    color: $grey-a !important;
}
.cl-grey-b {
    color: $grey-b !important;
}
.cl-grey-c {
    color: $grey-c !important;
}
.cl-grey-d {
    color: $grey-d !important;
}
// Background

.bg-primary {
    background-color: $p-color !important;
    color: $b-color;
}

.bg-secondary {
    background-color: $s-color !important;
    color: $b-color;
}

.bg-third {
    background-color: $t-color !important;
    color: $b-color;
}

.bg-fourth {
    background-color: $f-color !important;
}

.bg-medium {
    background-color: $m-color !important;
}

.bg-dark {
    background-color: $k-color !important;
}

.bg-light {
    background-color: $b-color !important;
}

.bg-danger {
    background-color: $danger-color !important;
}

.bg-success {
    background-color: $success-color !important;
}

.bg-warning {
    background-color: $warning-color !important;
}

.bg-info {
    background-color: $info-color !important;
}

.bg-link {
    background-color: $link-color !important;
}
.bg-grey-a {
    background-color: $grey-a !important;
}
.bg-grey-b {
    background-color: $grey-b !important;
}
.bg-grey-c {
    background-color: $grey-c !important;
}
.bg-grey-d {
    background-color: $grey-d !important;
}
// Font Awesome Size

.fa-1x {
    font-size: 1.5em !important;
}

.p-toast-detail {
  white-space: pre-line;
}
