/* Forms
   ========================================================================== */
.disabled {
  cursor: not-allowed;
}

textarea {
  resize: none;
}

label,
.label-control {
  @include font($size: rem(13), $weight: $fw-regular, $color: $t-color);
}

.form-control,
.custom-select {
  text-overflow: ellipsis;
  height: rem(40);
  @include font($size: rem(15), $weight: $fw-regular, $color: $p-color);

  &.tx-thin {
    font-weight: $fw-thin;
  }

  &.tx-x-light {
    font-weight: $fw-x-light;
  }

  &.tx-light {
    font-weight: $fw-light;
  }

  &.tx-regular {
    font-weight: $fw-regular;
  }

  &.tx-medium {
    font-weight: $fw-medium;
  }

  &.tx-s-bold {
    font-weight: $fw-s-bold;
  }

  &.tx-bold {
    font-weight: $fw-bold;
  }

  &.tx-x-bold {
    font-weight: $fw-x-bold;
  }

  &.tx-black {
    font-weight: $fw-black;
  }

  @include input-placeholder {
    text-overflow: ellipsis;
    @include font($size: rem(15), $weight: $fw-regular, $color: $grey-d);
  }

  &--login {
    height: rem(55);
    border-radius: 5px;
  }

  &--location {
    padding: 0;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    background: transparent;
    font-size: rem(16);
    @include all-trans;

    @include input-placeholder {
      font-size: rem(16);
      color: #454f63 !important;
    }

    &:focus,
    &:active {
      background: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $p-color;
    }
  }

  .card--form & {
    height: 1.5rem;
    padding: 0;
    border: none;
    //height: inherit;
    background: transparent;
  }

  &.is-dark {
    background: $p-color;
    border-color: $p-color;
    @include font($size: rem(15), $color: $b-color);

    @include input-placeholder {
      @include font($size: rem(15), $color: $grey-d);
    }
  }

  &.is-disabled,
  &[disabled] {
    opacity: 0.8;
  }

  &:focus {
    border-color: darken($color: $m-color, $amount: 10%);
    opacity: 1;
  }
}

.card--form textarea.form-control {
  height: initial;
}

.form-group--location {
  display: flex;

  .icon-dot {
    @include font($size: rem(12), $color: $s-color);
    margin-right: rem(10);
    margin-top: rem(5);
    align-items: flex-end;
  }
}

.form-group--autocomplete {
  position: relative;

  .form-control {
    background: $b-color url('../content/img/icn-search.png') no-repeat center right;
    padding-right: rem(30);
  }
}

.autocomplete-matches {
  z-index: 500;

  &__item {
    font-size: rem(14);
    //background: url('../content/img/icn-search.png') no-repeat center left;
  }
}

.input-group {
  .input-group-text {
    border-top: 0;
    border-left: 0;
    background: transparent;
    border-radius: 0 !important;
  }
  .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0 !important;
  }
}

// Custom select

.custom-select {
  background-image: url('../content/img/icn-angle-down.svg');
  .card--form & {
    background: $b-color url('../content/img/icn-angle-down.svg') no-repeat right center;
  }
}

// Radio / Check control

.form-input {
  padding-bottom: 0.3rem;
  display: flex;
  align-items: center;
}

.radio-control {
  display: inline-block;
  width: rem(25);
  height: rem(25);
  flex-shrink: 0;
  margin: 0;
  margin-right: rem(15);
  background: $b-color;
  border: rem(1) solid #bbbbbb;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &.is-disabled {
    background: #e7e7e7;
    border: rem(1) solid #e7e7e7;
    .radio-control__dot {
      background: #e7e7e7 url('../content/img/icn-check.svg') no-repeat center center;
    }
  }

  &.is-selected {
    background: $s-color;
    border: rem(1) solid $s-color;
    .radio-control__dot {
      background: $s-color url('../content/img/icn-check.svg') no-repeat center center;
    }
  }

  &__input {
    display: none;
  }

  &__dot {
    width: rem(17);
    height: rem(17);
    background: $s-color url('../content/img/icn-check.svg') no-repeat center center;
    border-radius: 100%;
    display: block;
    transition: 0.4s;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}

.radio-control__input:checked + .radio-control__dot {
  opacity: 1;
}

.check-control {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  flex-shrink: 0;
  margin: 0;
  margin-right: rem(15);
  background: $b-color;
  border: rem(1) solid #bbbbbb;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &__input {
    display: none;
  }

  &__dot {
    width: rem(17);
    height: rem(17);
    background: $s-color url('../content/img/icn-check.svg') no-repeat center center;
    display: block;
    transition: 0.4s;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}

.check-control__input:checked + .check-control__dot {
  opacity: 1;
}

.submitted .leaf.ng-invalid[formControlName] * {
  color: red;
}

fullstack-input-box.ng-dirty.ng-invalid .card--form,
fullstack-input-address-box.ng-dirty.ng-invalid .card--form,
fullstack-datepicker.ng-dirty.ng-invalid .card--form {
  border: 1px solid red;
}

fullstack-file-upload.ng-dirty.ng-invalid .input-file-upload {
  border: 1px solid red;
  border-radius: 0.25rem;
  // border-radius: $card-border-radius;
}

fullstack-input-multiple-select-box .p-multiselect  {
  width: 100%;
  border: none;
  color: #222222;
}
